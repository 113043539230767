import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import NatuerlichePerson from './NatuerlichePerson';
import Unternehmen from '../Unternehmen';

class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNaturalPerson: true };
  }


  componentDidMount() {
    this.setState({
      isNaturalPerson: this.isNaturalPerson(),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isNaturalPerson: this.isNaturalPerson(),
      });
    }
  }

  isNaturalPerson() {
    const { role, values } = this.props;

    if (role.indexOf('[') < 0) {
      if (values && role && values[role]
                && (values[role].company === true || values[role].company === 'true')) {
        return false;
      }
      return true;
    }

    const roleArray = role.split('[');
    const formattedRole = roleArray[0];
    const roleIndex = parseInt(roleArray[1].replace(']', ''), 10);

    if (values && formattedRole && values[formattedRole][roleIndex]
            && (values[formattedRole][roleIndex].company === true || values[formattedRole][roleIndex].company === 'true')) {
      return false;
    }
    return true;
  }

  render() {
    const {
      role, label, planVerfasserIsBevollmaechtigter, allowCompany,
    } = this.props;
    console.log(this.props.values);
    return (
      <div>
        {

                    label !== undefined
                      ? (
                        <div className="row form-group">
                          <div className="col-12 col-md-4 right-aligned-md">
                            <legend className="legend">{label}</legend>
                          </div>

                          {role === 'bevollmaechtigter' ? (
                            <div className="col-12 col-md-8">
                              <label>
                                <Field
                                  name="planVerfasserIsBevollmaechtigter"
                                  component="input"
                                  type="checkbox"
                                  aria-label={label}
                                />
                                {' siehe Planverfasser'}
                              </label>
                            </div>
                          ) : null}
                        </div>
                      )
                      : null
                }
        {
                    !planVerfasserIsBevollmaechtigter ? (
                      <div>

                        <div className="row form-group" hidden={!allowCompany}>
                          <div className="col-12 col-md-4 right-aligned-md">
                            <legend>Art</legend>
                          </div>
                          <div className="col-12 col-md-8 cb-group">
                            <label>
                              <Field
                                component="input"
                                type="radio"
                                name={`${role}.company`}
                                value={false}
                                checked={this.state.isNaturalPerson}
                                aria-label="Art"
                              />

                              Natürliche Person
                            </label>
                            <label>
                              <Field
                                component="input"
                                type="radio"
                                name={`${role}.company`}
                                value
                                aria-label="Firma"
                                checked={!this.state.isNaturalPerson}
                              />
                              Unternehmen
                            </label>
                          </div>
                        </div>

                        {this.state.isNaturalPerson
                          ? (
                            <NatuerlichePerson
                              role={role}
                              isEmailRequired={this.props.isEmailRequired}
                              {...this.props}
                            />
                          )
                          : <Unternehmen role={role} />}

                      </div>
                    ) : null
                }
      </div>
    );
  }
}

Person.defaultProps = {
  role: '',
  label: '',
  values: null,
  // planVerfasserIsBevollmaechtigter: true,
  // allowCompany : true,
};
Person.propTypes = {
  role: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object,
};

export default Person;
